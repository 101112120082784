<template>
  <div style="height: 100%">
    <bread></bread>
    <div class="center-content">
      <div class="iq-card-body">
        <div class="table-top flex-space-between">
          <el-page-header @back="goBack" :content=content> </el-page-header>
          <div>
            <el-button type="primary" size="small" @click="goBack" plain>取消</el-button>
            <el-button type="primary" size="small" @click="saveData('form')" v-if="prescriptionType == 2">保存</el-button>
          </div>
        </div>
        <div class="table-line"></div>
        <div class="table-content">
          <el-form ref="form" :model="form" label-width="100px" :rules="rules" style="width: 80%">
            <el-form-item label="处方名称:" prop="prescriptionName">
              <el-row v-if="prescriptionType == 2">
                <el-col :span="21"><el-input v-model="form.prescriptionName"></el-input></el-col>
                <el-col :span="2" :offset="1"><span class="primary optionBtn"
                    @click="drawer = true">载入经典方</span></el-col>
              </el-row>
              <el-row v-else>
                <el-col :span="24"><el-input v-model="form.prescriptionName" disabled></el-input></el-col>
              </el-row>
            </el-form-item>
            <el-form-item label="方剂处方:" :rules="{ required: true, message: '不能为空', trigger: 'blur' }">
              <div class="my-table">
                <el-table :data="form.mzPrescriptionDetail">
                  <el-table-column label="" width="30" type="index"></el-table-column>
                  <el-table-column label="中药材名称">
                    <template slot-scope="scope">
                      <el-form-item label="" :prop="'mzPrescriptionDetail[' + scope.$index + '].drugOrgName'"
                        :rules="rules.drugOrgName">
                        <el-select class="w-100" size="small" v-model="scope.row.drugOrgName" filterable remote
                          clearable placeholder="请输入关键词" :remote-method="remoteMethod" :loading="isLoading"
                          @change="handleSelect($event, scope.row)" :disabled="prescriptionType != 2">
                          <el-option v-for="item in options" :key="item.id"
                            :label="`${item.mzDrug.drugName}（${item.mzDrug.specification}/${item.mzDrug.specificationUnit}-${item.mzDrug.productFactory}）`"
                            :value="item">
                          </el-option>
                        </el-select>
                      </el-form-item>
                    </template>
                  </el-table-column>
                  <el-table-column prop="recommendDosage" label="推荐用量">

                    <template slot-scope="scope">
                      <el-form-item label="">
                        <el-input v-model="scope.row.recommendDosage" size="small" placeholder="推荐用量"
                          :disabled="prescriptionType != 2" disabled></el-input>
                      </el-form-item>
                    </template>
                  </el-table-column>
                  <el-table-column prop="weight" label="用量">

                    <template slot-scope="scope">
                      <el-form-item label="" :prop="'mzPrescriptionDetail[' + scope.$index + '].weight'"
                        :rules="rules.weight">
                        <el-input v-model="scope.row.weight" size="small" placeholder="用量"
                          :disabled="prescriptionType != 2"></el-input>
                      </el-form-item>
                    </template>
                  </el-table-column>
                  <el-table-column prop="usage" label="炮制方法">

                    <template slot-scope="scope">
                      <el-form-item label="">
                        <!-- <el-select v-model="scope.row.usage" size="small" placeholder="炮制方法" class="w-100" clearable
                        :disabled="prescriptionType != 2">
                          <el-option :label="item" :value="item" v-for="item in remark"></el-option>
                        </el-select> -->
                        <el-input v-model="scope.row.usage" size="small" placeholder="炮制方法"
                          :disabled="prescriptionType != 2"></el-input>
                      </el-form-item>
                    </template>
                  </el-table-column>
                  <el-table-column prop="decoct" label="煎制方法">

                    <template slot-scope="scope">
                      <el-form-item label="">
                        <el-select v-model="scope.row.decoct" size="small" placeholder="煎制方法" class="w-100" clearable
                          :disabled="prescriptionType != 2">
                          <el-option :label="item" :value="item" v-for="item in remark"></el-option>
                        </el-select>
                      </el-form-item>
                    </template>
                  </el-table-column>
                  <!-- <el-table-column prop="tradingPrice" label="价格"></el-table-column> -->
                  <el-table-column align="center" width="100px" v-if="prescriptionType == 2">

                    <template slot-scope="scope">
                      <el-button type="danger" size="mini" plain @click="handleDelete(scope.row, scope.$index)">
                        <i class="el-icon-delete" aria-hidden="true"></i>
                      </el-button>
                    </template>
                  </el-table-column>
                </el-table>
                <div style="padding: 0 10px;border-bottom: 1px solid #e0e0e0;margin-bottom: 10px;">
                  <span class="primary optionBtn" @click="handleAdd()" v-if="prescriptionType == 2">添加药品</span>
                </div>
                <el-row class="bottom-row">
                  <el-col :span="6">
                    <el-form-item label="剂数" prop="dosage">
                      <el-input-number controls-position="right" size="small" v-model="form.dosage" :precision="0"
                        :min="1" :step="1" :disabled="prescriptionType != 2"></el-input-number>剂
                    </el-form-item>
                  </el-col>
                  <el-col :span="6">
                    <el-form-item label="每日剂量" prop="dosageNumber">
                      <el-input-number controls-position="right" size="small" v-model="form.dosageNumber" :precision="0"
                        :min="1" :step="1" :disabled="prescriptionType != 2"></el-input-number>剂
                    </el-form-item>
                  </el-col>
                  <el-col :span="6">
                    <el-form-item label="用药频率" prop="frequency">
                      <el-select v-model="form.frequency" size="small" placeholder="用药频率" clearable
                        :disabled="prescriptionType != 2">
                        <el-option :label="item" :value="item" v-for="item in frequency"></el-option>
                      </el-select>
                    </el-form-item>
                  </el-col>
                  <el-col :span="6">
                    <el-form-item label="用法" prop="usage">
                      <el-select v-model="form.usage" size="small" placeholder="用法" class="w-100" clearable
                        :disabled="prescriptionType != 2">
                        <el-option :label="item" :value="item" v-for="item in usage"></el-option>
                      </el-select>
                    </el-form-item>
                  </el-col>
                </el-row>
                <el-form-item label="服用要求">
                  <el-select v-model="form.request" size="small" placeholder="" clearable
                    :disabled="prescriptionType != 2">
                    <el-option :label="item.adviceContent" :value="item.adviceContent"
                      v-for="item in request"></el-option>
                  </el-select>
                  <!-- <el-input type="textarea" autosize v-model="form.request" :rows="2" :disabled="prescriptionType != 2">
                  </el-input> -->
                </el-form-item>
                <div style="padding: 10px" class="flex-end">
                  <div class="warning optionBtn" @click="handleReset()" v-if="prescriptionType == 2">重置</div>
                </div>
              </div>
            </el-form-item>
          </el-form>
        </div>
      </div>
    </div>
    <el-drawer title="中药方剂库" size="550px" :visible.sync="drawer" :direction="direction">
      <div class="drawer_body">
        <div class="iq-search-bar">
          <el-form action="#" class="searchbox" @submit.native.prevent>
            <el-input v-model="Keyword" type="text" size="small" placeholder="方剂名称" @keyup.enter.native="search"
              clearable></el-input>
            <!-- <input type="text" class="text search-input" v-model="Keyword" placeholder="输入关键词搜索..." @keydown="search" /> -->
            <!-- <a class="search-link" href="javascript:;" @click="search"><i class="el-icon-search"></i></a> -->
          </el-form>
        </div>
        <div class="category_box">
          <el-button :class="categoryId == item.categoryId ? 'active' : ''" plain size="small"
            v-for="(item, index) in herbalNavList" @click="
            categoryId = item.categoryId
          searchHerbal()
            " :key="index">
            {{ item.categoryName }}</el-button>
        </div>
        <div class="herbalList">
          <el-table v-loading="loading" height="450" :data="herbalData" style="width: 100%" @row-click="rowClick">
            <el-table-column prop="herbalPrescriptionName" label="方剂名称"> </el-table-column>
            <el-table-column label="操作" width="150px">

              <template slot-scope="scope">
                <el-button size="mini" type="success" @click="loadRXFrom(scope.row)" plain>载入 </el-button>
                <!-- <el-button size="mini" type="danger" plain>忽略</el-button> -->
              </template>
            </el-table-column>
          </el-table>
          <el-pagination @current-change="ChangePage" :page-size="PageSize" :current-page="PageIndex"
            :total="StaffTotal" layout="total, prev, pager, next, jumper"></el-pagination>
        </div>
        <div class="herbalDetail">
          <el-table v-loading="loadings" height="450" :data="herbalDetail.compositions" style="width: 100%">
            <el-table-column prop="compositionName" label="成分">

              <template slot-scope="scope">
                <div v-html="scope.row.compositionName"></div>
              </template>
            </el-table-column>
            <el-table-column prop="recommendDosage" label="推荐用量"> </el-table-column>
            <el-table-column prop="dosage" label="用量"> </el-table-column>
            <el-table-column prop="remark" label="炮制方法"> </el-table-column>
          </el-table>
        </div>
      </div>
    </el-drawer>
  </div>
</template>

<script>
import { Patient } from '@/components/HospitalDomain/Patient/Patient'
import { Herbal } from '@/components/HospitalDomain/Patient/Herbal'
import { Outpatient } from "@/components/DrugDomain/Outpatient"
export default {
  data () {
    var patient = new Patient(this.TokenClient, this.Services.Authorization)
    var herbal = new Herbal(this.TokenClient, this.Services.Drug)
    var outpatient = new Outpatient(this.TokenClient, this.Services.Drug)
    return {
      OutpatientDomain: outpatient,
      patientDomain: patient,
      herbalDomain: herbal,
      tableData: [],
      addData: {},
      id: this.$route.query.id,
      form: {
        prescriptionName: '',
        type: 0,//0方剂（饮片）处方 1 成药处方 2 输液处方
        dosage: undefined,
        dosageNumber: undefined,
        frequency: null,
        usage: null,
        request: null,
        mzPrescriptionDetail: [
          {
            edit: true,
            drugOrgId: null,
            drugOrgName: '',
            recommendDosage: null,
            weight: null,
            decoct: null,
            usage: null,
          },
        ],
      },
      isLoading: false,
      options: [],
      dosageUnit: ["片", "粒", "支", "袋", "枚", "瓶", "滴", "适量", "ug", "mg", "g", "ul", "ml", "IU"],
      usage: ["口服", "外用"],
      frequency: ["1日1次(qd)", "1日2次(bid)", "1日3次(tid)", "隔日1次(qod)", "必要时(prn)", "1日4次(qid)", "1周1次(qwd)", "隔周1次(qow)", "隔天1次(qod)", "每晚1次(qn)", "立即(st)"],
      remark: ["先煎", "后下", "包煎", "另煎", "烊化", "冲服", "捣碎"],
      request: [],
      rules: {
        prescriptionName: [{ required: true, message: '请输入处方名称', trigger: 'blur' }],
        drugOrgName: [{ required: true, message: '请输入药品名称', trigger: 'blur' }],
        groupNumber: [{ required: true, message: '请输入组号', trigger: 'blur' }],
        usage: [{ required: true, message: '请选择用法', trigger: 'change' }],
        frequency: [{ required: true, message: '请选择用药频率', trigger: 'change' }],
        dosage: [{ required: true, message: '请输入剂数', trigger: 'blur' }],
        dosageNumber: [{ required: true, message: '请输入每日剂数', trigger: 'blur' }],
        frequency: [{ required: true, message: '请选择用药频率', trigger: 'change' }],
        weight: [{ required: true, message: '请输入用量', trigger: 'blur' }],
        remark: [{ required: true, message: '请选择炮制方法', trigger: 'change' }],
      },
      drawer: false,
      direction: 'rtl',
      Keyword: '',
      searchId: '0',
      categoryId: 0,
      PageIndex: 1,
      PageTotal: 1,
      PageSize: 15,
      StaffTotal: 0,
      remarkOption: [
        {
          value: '炒法',
        },
        {
          value: '炙法',
        },
        {
          value: '煅法',
        },
        {
          value: '蒸法',
        },
        {
          value: '煮法',
        },
        {
          value: '燀法',
        },
        {
          value: '复制法',
        },
        {
          value: '发酵法',
        },
        {
          value: '发芽法',
        },
        {
          value: '制霜法',
        },
        {
          value: '烘焙法',
        },
        {
          value: '煨法',
        },
        {
          value: '提净法',
        },
        {
          value: '水飞法',
        },
        {
          value: '干馏法',
        },
        {
          value: '特殊制法',
        },
      ],
      RXForm: {
        userId: JSON.parse(sessionStorage.getItem('userInfo')).id,
        patientId: 0,
        patientDiagnoseId: 0,
        prescriptionName: '',
        isSelef: true,
        dosage: null,
        number: null,
        prescriptionType: 2,
        organizationId: JSON.parse(sessionStorage.getItem('userInfo')).organizationId,
        compositions: [
          {
            edit: true,
            compositionName: '',
            remark: '',
            dosage: '',
            category: null,
            categoryName: null,
            compositionAlias: null,
            compositionId: null,
          },
        ],
      },
      herbalNavList: [],
      herbalData: [],
      loading: false,
      herbalDetail: {},
      loadings: false,
      EditDrugCompositio: [],
      preview: false,
      compatibilityDialog: false,
      compatibilityRes: [],
      prescriptionType: 2,//1个人 2机构 3总后台
      content: '',
    }
  },
  mounted () {
    if (this.id) {
      this.getPrescriptionInfo()
    } else {
      this.content = '新增方剂处方'
    }
    this.getHerbalCategorys()
    this.getTakeRequest()
  },
  methods: {
    getTakeRequest () {
      var _this = this
      _this.OutpatientDomain.TakeRequestList('', 1,
        function (data) {
          _this.request = data.data.results
        },
        function (err) {
          console.log(err)
        }
      )
    },
    getPrescriptionInfo () {
      var _this = this
      _this.OutpatientDomain.PrecriptionDetail(
        _this.id,
        function (res) {
          _this.prescriptionType = res.data.prescriptionType
          if (_this.prescriptionType == 1) {
            _this.content = '方剂处方详情'
          } else {
            _this.content = '编辑方剂处方'
          }
          _this.form.id = res.data.id
          _this.form.prescriptionName = res.data.prescriptionName
          _this.form.type = res.data.type
          _this.form.dosage = res.data.dosage
          _this.form.dosageNumber = res.data.dosageNumber
          _this.form.frequency = res.data.frequency
          _this.form.usage = res.data.usage
          _this.form.request = res.data.request
          _this.form.mzPrescriptionDetail = []
          res.data.mzPrescriptionDetail.forEach((item) => {
            var row = {
              drugOrgId: item.drugOrgId ? item.drugOrgId : null,
              drugOrgName: item.drugOrgName,
              recommendDosage: item.recommendDosage,
              weight: item.weight,
              usage: item.usage,
              decoct: item.decoct,
              tradingPrice: item.tradingPrice,
            }
            _this.form.mzPrescriptionDetail.push(row)
          })
        },
        function (error) {
          console.log(error)
        }
      )
    },
    async rowClick (row) {
      var _this = this
      _this.loadings = true
      let res = await this.getHerbalPrescriptionDetail(row.hashKey)
    },
    getHerbalPrescriptionDetail (hashKey) {
      var _this = this
      _this.loadings = true
      return new Promise((resolve) => {
        _this.herbalDomain.getHerbalPrescriptionDetail(
          hashKey,
          function (data) {
            var list = []
            _this.herbalDetail = data.data
            for (let index = 0; index < _this.herbalDetail.compositions.length; index++) {
              const item = _this.herbalDetail.compositions[index]

              let content = item.compositionName
              let reg = /\[\[(.*?)\]\]/g
              let result = []
              while (true) {

                let itemArr = reg.exec(content)
                if (itemArr === null) {
                  break
                }
                result.push(itemArr[0])//[[麸炒|Dict|48111]]
              }
              let longestString = '' // 存放最长字符串的变量
              if (result.length > 1) {

                result.map((item) => {
                  if (item.length > longestString.length) {
                    item = longestString
                  }
                })
              } else {
                longestString = result[0] // 存放最长字符串的变量
              }


              let compositionName = item.compositionName.replace(/\[\[(.+?)\|(.+?)\|(.+?)\]\]/, "$1")
              let drugHashKey = longestString.replace(/\[\[(.+?)\|(.+?)\|(.+?)\]\]/, "$3")
              item.compositionName = compositionName
              if (compositionName == drugHashKey || !drugHashKey || drugHashKey == "Search") {
                drugHashKey = ''
              }
              let map = {
                "drugName": compositionName,
                "drugHashKey": drugHashKey,
                "recommendDosage": item.recommendDosage,
                "weight": item.dosage,
                "remark": item.remark,
              }
              list.push(map)

            }
            _this.loadings = false
            resolve(list)
          },
          function (error) {
            resolve(false)
            console.log("左侧分类请求失败:", error)
          }
        )
      })
    },
    async loadRXFrom (row) {
      var _this = this
      _this.loadings = true
      _this.RXForm.compositions = []
      let res = await this.getHerbalPrescriptionDetail(row.hashKey)
      _this.drawer = false
      let nowNum = 0
      _this.OutpatientDomain.LoadPrescriptionOrg(res,
        function (data) {
          data.data.forEach(item => {
            // if (item.drugOrgId) {
            nowNum++
            var map = {
              drugOrgId: item.drugOrgId ? item.drugOrgId : null,
              drugOrgName: item.drugOrgName,
              recommendDosage: item.recommendDosage,
              decoct: null,
              weight: item.weight,
              usage: item.remark,
              tradingPrice: item.tradingPrice,
            }
            if (nowNum == 1 && !_this.form.mzPrescriptionDetail[0].drugOrgName) {
              _this.form.mzPrescriptionDetail = []
            }
            let list = _this.form.mzPrescriptionDetail.filter(i => i.drugOrgName == map.drugOrgName && i.remark == map.remark)
            if (list.length > 0) {
              _this.form.mzPrescriptionDetail.forEach((item) => {
                if (item.drugOrgName == map.drugOrgName && item.remark == map.remark) {
                  if (parseInt(item.weight) < parseInt(map.weight)) {
                    item.weight = map.weight
                  }
                }
              })
            } else {
              _this.form.mzPrescriptionDetail.push(map)
            }
            console.log(_this.form.mzPrescriptionDetail)
            // }
          })
          if (data.data.length > nowNum) {
            _this.$message({
              message: "载入失败，请添加相应药品",
              type: "warning",
            })
          } else {
            _this.form.prescriptionName = row.herbalPrescriptionName
            _this.form.mzPrescriptionDetail = Array.from(new Set(_this.form.mzPrescriptionDetail.map(JSON.stringify)), JSON.parse)
          }

        },
        function (error) {
          console.log('左侧分类请求失败:', error)
        }
      )
    },
    search () {
      // this.getHerbalCategorys()
      this.herbalData = []
      this.PageIndex = 1
      this.searchHerbal()
    },
    ChangePage (pageIndex) {
      this.PageIndex = pageIndex
      this.searchHerbal(this.categoryId)
    },
    getHerbalCategorys () {
      var _this = this
      _this.herbalNavList = []
      _this.herbalDomain.Categorys(
        function (data) {
          _this.herbalNavList = data.data
        },
        function (error) {
          console.log('左侧分类请求失败:', error)
        }
      )
    },
    searchHerbal () {
      var _this = this
      _this.loading = true
      _this.herbalDomain.Herbals(
        _this.searchId,
        _this.categoryId,
        _this.PageIndex,
        _this.Keyword,
        function (data) {
          _this.herbalData = data.data.results
          _this.PageIndex = data.data.pageIndex
          _this.PageSize = data.data.pageSize
          _this.PageTotal = data.data.pageCount
          _this.StaffTotal = data.data.dataTotal
          _this.loading = false
        },
        function (error) {
          _this.loading = false
          console.log('请求失败:', error)
        }
      )
    },
    remoteMethod (queryString) {
      var _this = this
      if (queryString !== "") {
        this.isLoading = true
        setTimeout(() => {
          this.isLoading = false
          _this.OutpatientDomain.DrugByOrgIn(
            queryString,
            function (res) {
              _this.options = res.data
            },
            function (error) {
              console.log("药品列表请求失败!请刷新重试:", error)
            }
          )
        }, 200)
      } else {
        this.options = []
      }
    },
    handleSelect (e, map) {
      map.drugOrgId = e.id
      map.drugOrgName = e.mzDrug.drugName
      map.recommendDosage = e.mzDrug.recommendDosage
      map.tradingPrice = e.tradingPrice
      // map.weight = e.weight
      map.remark = e.remark ? e.remark : e.mzDrug.remark
      this.form.frequency = e.frequency ? e.frequency : e.mzDrug.frequency
      this.form.usage = e.usage ? e.usage : e.mzDrug.usage
    },
    saveData (formName) {
      var _this = this
      _this.$refs[formName].validate((valid) => {
        if (valid) {
          if (_this.form.id) {
            _this.OutpatientDomain.EditMZPrecription(_this.form,
              function (data) {
                _this.$message({
                  message: "修改成功",
                  type: "success",
                })
                _this.$router.go(-1)
              },
              function (error) {
                console.log(error)
              })
          } else {
            _this.OutpatientDomain.AddMZPrecription(_this.form,
              function (data) {
                _this.$message({
                  message: "添加成功",
                  type: "success",
                })
                _this.$router.go(-1)
              },
              function (error) {
                console.log(error)
              })
          }

        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    handleReset () {
      let row = {
        edit: true,
        edit: true,
        drugOrgId: null,
        drugOrgName: '',
        recommendDosage: null,
        weight: null,
        remark: null,
        tradingPrice: null,
      }
      this.options = []
      this.form.mzPrescriptionDetail = []
      this.form.mzPrescriptionDetail.push(row)
    },
    handleAdd () {
      let row = {
        edit: true,
        drugOrgId: null,
        drugOrgName: '',
        recommendDosage: null,
        weight: null,
        remark: null,
        tradingPrice: null,
      }
      this.options = []
      this.form.mzPrescriptionDetail.push(row)
    },
    handleDelete (row, index) {
      if (this.form.mzPrescriptionDetail.length == 1) {
        this.$message({
          message: '请至少保留一行',
          type: 'warning',
        })
        return
      }
      this.$message({
        message: '删除成功',
        type: 'success',
      })
      this.form.mzPrescriptionDetail.splice(index, 1)
    },
    goBack () {
      this.$router.go(-1)
    },
  },
}
</script>

<style scoped lang="scss">
.iq-card-body {
  padding: 0 0 15px;

  .table-top {
    width: 96%;
    margin: 0 auto;
  }

  .table-line {
    width: 100%;
    height: 6px;
    background: #f5f6f7;
    margin: 0 0 15px;
  }

  .table-content {
    padding-left: 20px;
    height: calc(100% - 30px - 73px);
    overflow-y: auto;

    .title {
      background: #f4f5f7;
      height: 60px;
      line-height: 60px;
      padding: 0 15px;
      margin-bottom: 20px;
    }

    ::v-deep .el-table__row>td {
      /* 去除表格线 */
      border: none !important;
    }

    ::v-deep .el-table th.is-leaf {
      /* 去除上边框 */
      border: none !important;
    }

    ::v-deep .el-table::before {
      /* 去除下边框 */
      height: 0 !important;
    }

    ::v-deep .el-table .el-table__cell {
      padding: 0 !important;
    }

    .my-table {
      border: 1px solid #ebeef5;
      padding: 10px 5px;
    }

    ::v-deep .bottom-row .el-form-item {
      margin-bottom: 22px !important;
    }
  }
}

.drawer_body {
  padding: 0 20px 20px;
}

.searchbox {
  position: relative;
}

.category_box {
  padding: 15px 0;
  border-bottom: 1px solid #eeeeee;
}

.category_box .el-button {
  margin: 5px;
}

.category_box .el-button.active {
  color: #409EFF;
  background-color: var(--baseColor);
  border-color: var(--baseColor);
}

.tab /deep/ .el-form-item__content {
  height: 55px;
}

.tab /deep/ .el-form-item__content .el-form-item__error {
  top: 40px;
}

.elSelect /deep/ .el-select-dropdown__item span {
  max-width: 170px;
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
</style>
